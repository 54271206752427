const blurbs = [
  {
    title: 'Sommarens glidbilar',
    href: '/',
    imageSrc: '/assets/images/test.jpg',
  },
  {
    title: 'Elbilar med galen räckvidd',
    href: '/',
    imageSrc: '/assets/images/test.jpg',
  },
  {
    title: 'Bästa semesterpackaren',
    href: '/',
    imageSrc: '/assets/images/test.jpg',
  },
];

const urls = [
  {
    label: 'Se alla bilar',
    href: '/fordon',
  },
  {
    label: 'Värdera din bil',
    href: '/salja-begagnad-bil',
  },
];

export const proof = {
  blurbs,
  cars: [],
  title: 'Senaste annonserna på Blipp',
  urls,
};

export const proofBuy = {
  title: 'Senaste annonserna på Blipp',
  intro:
    'Alla bilar som säljs med hjälp av Blipp går igenom vår digitala bakgrundskontroll och säljaren är verifierad med BankID. Blipp löser allt från billån, försäkring, bakgrundskontroll till betalning och överlämning.',
  cars: [],
  blurbs,
  urls,
};

export const proofCompany = {
  title: 'Bilar som går att företagsleasa',
  subTitle: 'Nyligen tillagda',
  intro:
    'Kolla in alla våra fordon här. Blipp löser allt från billån, försäkring, bakgrundskontroll till betalning och överlämning.',
  cars: [],
  blurbs,
  urls,
};

export const proofSell = {
  title: 'Ska du också köpa en ny bil?',
  subTitle: 'Nyligen tillagda',
  intro:
    'Kolla in alla våra bilar, motorcyklar och husbilar här. Blipp löser allt från billån, försäkring, bakgrundskontroll till betalning och överlämning.',
  cars: [],
  blurbs,
  urls,
};
