import React, { useRef, useState } from 'react';
import Image from 'next/image';

import useEventListener from 'utils/useEventListener';
import useOnClickOutside from 'utils/useOnClickOutside';

import { CustomModal } from './CustomModal';
import styles from './MovieModal.module.scss';

interface Props {
  src: string;
  imgWidth?: number;
  imgHeight?: number;
  objectFit?: any;
}

export const MovieModal: React.FC<Props> = ({
  src,
  imgWidth = 318,
  imgHeight = 629,
  objectFit = 'contain',
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);
  useEventListener('keydown', handleEscape);

  return (
    <div>
      <button
        className={styles.button}
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Image
          alt="Cover image for Blipp trailer"
          src={src}
          objectFit={objectFit}
          quality={90}
          width={imgWidth}
          height={imgHeight}
          className={styles.image}
        />
      </button>

      {isOpen && (
        <CustomModal
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div ref={ref}>{children}</div>
        </CustomModal>
      )}
    </div>
  );
};
