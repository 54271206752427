import useSWR from 'swr';
import { useEffect, useRef } from 'react';

import { ClientApiService } from 'services/ClientApiService';
import { MarketplaceAdsResponse } from 'types/MarketplaceAdsResponse';

export function useVehicleData(
  params: string,
  serverData?: MarketplaceAdsResponse
): {
  data?: MarketplaceAdsResponse;
  isLoading: boolean;
  error: any;
} {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const { data, error } = useSWR(
    ['/api/proxy', { method: 'getMarketplaceAds', payload: { params } }],
    ClientApiService.swrFetcher,
    {
      fallbackData: hasMounted.current ? undefined : { body: { ...serverData } }, // Only serve fallbackData on first render to prevent double fetching
      revalidateIfStale: false,
      revalidateOnMount: true, // TODO fix this to not need to re-fetch from server to client with same data
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    data: data?.body,
    error,
    isLoading: !error && !data,
  };
}
