import React from 'react';

import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { arrowRight } from 'icons/default';
import { BackgroundImage } from 'components/BackgroundImage';
import useMediaQuery from 'utils/useMediaQuery';

import styles from './StartHero.module.scss';

export const StartHero: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState(Boolean);
  const max768px = useMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    setIsMobile(max768px);
  }, [max768px]);

  return (
    <div className={styles.wrapper}>
      {isMobile ? (
        <BackgroundImage
          src="/assets/images/herostart-mobile.jpg"
          alt="Woman watching the sunset from car."
          style={{ position: 'relative', paddingBottom: '62.66%' }}
          priority
        />
      ) : (
        <BackgroundImage
          src="/assets/images/herostart.jpg"
          alt="Woman watching the sunset from car."
          priority
        />
      )}
      <div className={styles.content}>
        <div>
          <h1>
            Köp eller sälj bil <br />
            med experthjälp
          </h1>
          <ul>
            <li>Billån och möjlighet till garanti </li>
            <li>Säkra betalningar och hjälp vid affären</li>
            <li>Lösen av befintligt lån och ägarbyte</li>
          </ul>
        </div>

        {isMobile ? (
          <div className={styles.actions}>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryMintSweet}
              size={isMobile ? ButtonSize.Small : ButtonSize.Large}
              stretch
              isJustifyCenter
              href="/kopa-begagnad-bil"
            >
              Köpa bil
            </Button>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryYellowish}
              size={ButtonSize.Small}
              stretch
              isJustifyCenter
              href="/lanelofte-billan"
            >
              Billån
            </Button>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryRedish}
              size={ButtonSize.Small}
              stretch
              isJustifyCenter
              href="/foretag/release"
            >
              Re:lease
            </Button>
          </div>
        ) : (
          <div className={styles.actions}>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryMintSweet}
              size={ButtonSize.Large}
              trailingIcon={arrowRight}
              stretch
              href="/kopa-begagnad-bil"
            >
              Köpa bil
            </Button>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryYellowish}
              size={ButtonSize.Large}
              trailingIcon={arrowRight}
              stretch
              href="/lanelofte-billan"
            >
              Billån
            </Button>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.PrimaryRedish}
              size={ButtonSize.Large}
              trailingIcon={arrowRight}
              stretch
              href="/foretag/release"
            >
              Re:lease
            </Button>
          </div>
        )}
        <div className={styles.trustpilot}>
          <span>Utmärkt</span>
          <img width="120" src="./assets/icons/logo/Trustpilot_rating.svg" alt="Trustpilot logo" />
          <a href="https://se.trustpilot.com/review/blipp.se" target="_BLANK" rel="noreferrer">
            <img
              width="100"
              src="./assets/icons/logo/Trustpilot_brandmark.svg"
              alt="Trustpilot logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
