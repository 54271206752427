import React from 'react';

import { Divider } from 'components/Divider';
import { Colors } from 'enums/Colors';

import styles from './List.module.scss';
import { ListItem, ListItemProps, ListItemSize } from './ListItem';

interface ListProps {
  items: ListItemProps[];
  bgColor?: Colors;
  dividerColor?: Colors;
  size?: ListItemSize;
  listItemLabelColor?: Colors;
  listItemValueColor?: Colors;
}

export const List: React.FC<ListProps> = ({
  bgColor = Colors.White,
  items,
  size = ListItemSize.Normal,
  listItemLabelColor = undefined,
  listItemValueColor = undefined,
  dividerColor = undefined,
}) => (
  <ul
    className={`${styles.list} ${styles[size]}`}
    style={{ backgroundColor: `var(--color-${bgColor})` }}
  >
    {items.map((item: ListItemProps, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={item.label + item.value + index}>
        <ListItem
          symbol={item.symbol}
          label={item.label}
          labelColor={listItemLabelColor}
          value={item.value}
          valueColor={listItemValueColor}
          tooltip={item.tooltip}
          size={item.size || size}
        />
        <Divider thickness={1} borderColor={dividerColor} />
      </React.Fragment>
    ))}
  </ul>
);
