import React, { useRef, useState } from 'react';
import { ModalPortal } from '@weahead/react-customizable-modal';

import useOnClickOutside from 'utils/useOnClickOutside';
import useEventListener from 'utils/useEventListener';
import { Icon } from 'components/tokens/Icon';
import { closeCircle } from 'icons/default';

import styles from './Modal.module.scss';

interface Props {
  ButtonContent: JSX.Element;
}

export const Modal: React.FC<Props> = ({ ButtonContent, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);
  useEventListener('keydown', handleEscape);

  return (
    <>
      <button type="button" className={styles.button} onClick={() => setIsOpen(!isOpen)}>
        {ButtonContent}
      </button>
      {isOpen && (
        <ModalPortal id="modalpopup">
          <div className={styles.modal} ref={ref}>
            {children}
            <button type="button" className={styles.close} onClick={() => setIsOpen(false)}>
              <Icon icon={closeCircle} size={24} />
            </button>
          </div>
        </ModalPortal>
      )}
    </>
  );
};
