import React from 'react';

import { Layout } from 'components/Layout';
import { StartHero } from 'components/sections/StartHero';
import { WhatYouGet } from 'components/sections/WhatYouGet';
import { Faq, Topic } from 'components/sections/Faq';
import { Proof } from 'components/sections/Proof';
import { Magazine, MagazineArticleCard } from 'components/sections/Magazine';
import { Reviews } from 'components/sections/Reviews';
import { reviews } from 'content/reviews';
import { ProofProps } from 'components/sections/Proof/Proof';
import { Gutter } from 'components/Gutter';
import { MarketplaceAdsResponse } from 'types/MarketplaceAdsResponse';
import { Calculate } from 'components/sections/Calculate';
import { LoanType } from 'enums/LoanType';
import useMediaQuery from 'utils/useMediaQuery';
import { Image } from 'components/sections/Image';

import styles from './Start.module.scss';

interface Props {
  articles: MagazineArticleCard[];
  faq: {
    title: string;
    questions: Topic[];
  };
  proof: ProofProps;
  marketplaceAds: MarketplaceAdsResponse | undefined;
  isLoading: boolean;
  error: any;
}

export const Start: React.FC<Props> = ({
  articles,
  faq,
  proof,
  marketplaceAds,
  error,
  isLoading,
}) => {
  const hasDataResult = !error && !isLoading && marketplaceAds?.payload?.items.length;
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Layout>
      <StartHero />

      <Gutter size="negative">
        <div className={styles.whatYouGetWrapper}>
          <WhatYouGet mediaVariant="image" hidePartners />
        </div>
      </Gutter>

      <Gutter size="small">
        <div className={styles.calcWrapper} id="calculator">
          <Calculate
            imageSrc="/assets/images/calculator-woman-trustpilot.png"
            loanType={LoanType.NordeaLoan}
          />
        </div>
      </Gutter>
      <Gutter size="medium">
        <div>
          {hasDataResult ? (
            marketplaceAds.payload &&
            marketplaceAds.payload.items && (
              <Proof
                subTitle={proof.title}
                cars={marketplaceAds.payload.items}
                blurbs={proof.blurbs}
                urls={proof.urls}
              />
            )
          ) : (
            <div className={`${styles['loading-wrapper']} ${styles.box}`}>
              <div className={styles.box}>
                <video autoPlay loop muted playsInline>
                  <source src="/assets/videos/blipp-logotyp-azur-animation.mp4" type="video/mp4" />
                </video>

                <p>Laddar...</p>
              </div>
            </div>
          )}
        </div>
      </Gutter>
      <Gutter size="xlarge">
        <Reviews title={reviews.title} reviews={reviews.reviews} />
      </Gutter>

      <Gutter size="large">
        {isMobile ? (
          <Image
            rounded
            src="/assets/images/my-ride-my-rules-mobile.jpg"
            alt="An electric car and two people in front of a wooden house"
            title="My ride. My rules."
            intro="Att ha en egen bil ska göra dig fri, inte begränsad."
          />
        ) : (
          <Image
            rounded
            src="/assets/images/my-ride-my-rules-desktop.jpg"
            alt="An electric car and two people in front of a wooden house"
            title="My ride. My rules."
            intro="Att ha en egen bil ska göra dig fri, inte begränsad."
          />
        )}
      </Gutter>
      <Gutter size="large">
        <Magazine articles={articles} />
      </Gutter>
      <Gutter size="large">
        <Faq title={faq.title} questions={faq.questions} />
      </Gutter>
    </Layout>
  );
};
