import { stringify } from 'qs';

export interface IFilters {
  tag: string;
  rear_trunk_volume?: Record<string, unknown>;
  number_of_seats?: Record<string, unknown>;
  range?: Record<string, unknown>;
  price?: Record<string, unknown>;
  series?: Array<string>;
  municipality_county?: Array<string>;
  model_year?: Record<string, unknown>;
  brand?: Array<string>;
  gearbox?: Array<string>;
  classification_en?: Array<string>;
  extrapolated_category?: Array<string>;
  vehicle_type?: Array<string>;
  owner_is_company?: Boolean;
  leased?: Boolean;
  is_company_leasable?: Boolean;
  horsepower?: Record<string, number>;
  latest_milage?: Record<string, number>;
  colour?: Array<string>;
  fuel?: Array<string>;
}

interface IGetUrlWithParams {
  search?: string | string[];
  perPage?: number;
  page?: number;
  filters?: IFilters;
  plats?: string;
}

export const getMarketplaceAdsParams = ({
  search = '',
  page = 1,
  perPage = 0,
  plats = '',
  filters = { tag: '' }, // tag needs to filter out sold items
}: IGetUrlWithParams) => {
  const params: Record<string, unknown> = {
    search,
    filters,
    sort: { column: 'published_date', order: 'desc' },
    page,
    plats,
    per_page: perPage,
  };

  return stringify(params);
};
