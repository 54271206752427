import React from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';

import { Start } from 'components/views/Start';
import { faq } from 'content/faq';
import { StrapiService } from 'lib/strapi/StrapiService';
import { MagazineArticleCard } from 'components/sections/Magazine';
import { proof } from 'content/mocked/proof';
import { MarketplaceAdsResponse } from 'types/MarketplaceAdsResponse';
import { withSessionSsr } from 'lib/auth';
import { getMarketplaceAdsParams } from 'utils/marketplaceAdsFilter';
import { useVehicleData } from 'lib/api/swr/useVehiclesData';

interface Props {
  articles: MagazineArticleCard[];
  marketplaceAds: MarketplaceAdsResponse;
}

const Home: NextPage<Props> = ({ articles, marketplaceAds }) => {
  const params = getMarketplaceAdsParams({ perPage: 20 });
  const { data: clientSideData, isLoading, error } = useVehicleData(params, marketplaceAds);

  return (
    <>
      <Head>
        <title>Köp eller sälj bil med experthjälp - Blipp</title>
        <meta property="og:title" content="Köp eller sälj bil med experthjälp - Blipp" />
        <meta property="og:url" content="https://blipp.se/" />
        <meta
          property="og:description"
          content="Spara pengar när du ska köpa eller sälja begagnade bilar. Ansök om billån hos oss och köp din nya bil tryggt och enkelt!"
        />
        <meta
          name="description"
          content="Spara pengar när du ska köpa eller sälja begagnade bilar. Ansök om billån hos oss och köp din nya bil tryggt och enkelt!"
        />
      </Head>

      <Start
        articles={articles}
        faq={faq}
        marketplaceAds={clientSideData}
        proof={proof}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = withSessionSsr(async () => {
  const articles = await StrapiService.getLatestMagazineArticles(3);

  return {
    props: {
      articles,
    },
  };
});

export default Home;
