import React from 'react';
import {
  useTrapFocus,
  useBodyScrollLock,
  useCloseOnEsc,
  ModalPortal,
} from '@weahead/react-customizable-modal';

import { Icon, IconType } from 'components/tokens/Icon';
import { closeCircle } from 'icons/default';
import { Colors } from 'enums/Colors';

import styles from './CustomModal.module.scss';

const Overlay: React.FC = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 999999999999,
    }}
  >
    {children}
  </div>
);

interface Props {
  onClose(): void;
}

export const CustomModal: React.FC<Props> = ({ onClose, children }) => {
  useBodyScrollLock();
  useCloseOnEsc(onClose);
  const modalRef = useTrapFocus();
  return (
    <ModalPortal id="customModal">
      <Overlay>
        <div className={styles.modal} ref={modalRef}>
          <button type="button" className={styles.close} onClick={onClose}>
            <Icon icon={closeCircle} size={24} type={IconType.Stroke} color={Colors.StoneGrey} />
          </button>
          {children}
        </div>
      </Overlay>
    </ModalPortal>
  );
};
