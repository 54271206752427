import React from 'react';
import Image from 'next/image';

import { UspList } from 'components/UspList';
import { PartnerSlider } from 'components/PartnerSlider';
import { deal, headphonesCustomerSupport, advertisingMegaphone } from 'icons/default';
import { Car } from 'icons/symbol';
import { Direction } from 'enums/Direction';
import { MovieModal } from 'components/MovieModal';

import whatYouGet from '../../../../public/assets/images/what-you-get.jpg';

import styles from './WhatYouGet.module.scss';

interface Props {
  mediaVariant?: 'image' | 'movie';
  hidePartners?: boolean;
}
/**
 * For use in other components. The parent needs to have position:relative set.
 * @constructor
 */
export const WhatYouGet: React.FC<Props> = ({ mediaVariant = 'image', hidePartners }) => {
  const items = [
    {
      icon: deal,
      heading: 'Ansöka om samma billån som hos bilhandlaren',
      text: 'Köp en bil på garageuppfarten med samma billån som hos bilhandlaren. Samma ränta för alla.',
      link: '/lanelofte-billan',
    },
    {
      icon: Car,
      heading: 'Lägga om din företagsleasing eller ansöka om en ny',
      text: 'Vi har hjälpt hundratals företagare att förbättra sina leasingkostnader.',
      link: '/foretag/release',
    },
    {
      icon: headphonesCustomerSupport,
      heading: 'Få experthjälp när du köper en bil på garageuppfarten',
      text: 'Personlig hjälp för en säker affär och hjälp med ägarbyte.',
      link: '/kopa-begagnad-bil',
    },

    {
      icon: advertisingMegaphone,
      heading: 'Annonsera på Blipp',
      text: 'Få bättre betalt och erbjud finansiering, garanti och försäkring när du säljer din bil.',
      link: '/salja-begagnad-bil',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {mediaVariant !== 'movie' && (
            <Image
              alt="Med blipp kan du..."
              src={whatYouGet}
              width={475}
              height={475}
              quality={90}
              className={styles.image}
              objectFit="cover"
              placeholder="blur"
            />
          )}
          {mediaVariant === 'movie' && (
            <div className={styles.movie}>
              <MovieModal
                src="/assets/images/what-you-get.jpg"
                imgWidth={475}
                imgHeight={475}
                objectFit="cover"
              >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video controls className={styles.player} autoPlay style={{ width: '100%' }}>
                  <source src="/assets/movies/trailer.webm" type="video/webm" />
                  <source src="/assets/movies/trailer.mp4" type="video/mp4" />
                  Sorry, your browser doesn&apos;t support embedded videos.
                </video>
              </MovieModal>
            </div>
          )}
        </div>
        <div>
          <h2>Med Blipp kan du...</h2>
          <UspList items={items} listVariant={Direction.Vertical} />
        </div>
      </div>
      {!hidePartners && (
        <div className={styles.logos}>
          <h3>Tack vare våra partners</h3>
          <PartnerSlider />
        </div>
      )}
    </div>
  );
};
