import React from 'react';

import { Icon, SVG } from 'components/tokens/Icon';
import { questionCircle } from 'icons/default';
import { Colors } from 'enums/Colors';
import { Modal } from 'components/Modal';

import styles from './ListItem.module.scss';

export enum ListItemSize {
  Normal = 'normal',
  Small = 'small',
  Large = 'large',
  XLarge = 'xlarge',
}

export interface ListItemProps {
  iconColor?: Colors;
  symbol?: SVG;
  label: string;
  labelColor?: Colors;
  value?: string | React.ReactNode;
  valueColor?: Colors;
  tooltip?: string | React.ReactNode;
  tooltipColor?: Colors;
  size?: ListItemSize;
  iconSize?: number;
}

export const ListItem: React.FC<ListItemProps> = ({
  symbol,
  label = '',
  labelColor = Colors.DarkBlue,
  value,
  valueColor = '',
  tooltip,
  tooltipColor = Colors.DarkBlue100,
  size = ListItemSize.Normal,
  iconColor,
  iconSize = size === ListItemSize.XLarge ? 36 : 24,
}) => (
  <li
    className={`${styles.ListItem} ${styles[size]}`}
    style={{ color: `var(--color-${labelColor})` }}
  >
    <div>
      {symbol && <Icon size={iconSize} icon={symbol} color={iconColor ?? labelColor} />}
      <span className={styles.label}>{label}</span>
    </div>
    <div>
      <span className={styles.value} style={{ color: valueColor }}>
        {value}
      </span>
      {tooltip && (
        <Modal ButtonContent={<Icon icon={questionCircle} size={17} color={tooltipColor} />}>
          {tooltip}
        </Modal>
      )}
    </div>
  </li>
);
