import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { ProductCard, ProductCardTheme } from 'components/ProductCard/ProductCard';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import useMediaQuery from 'utils/useMediaQuery';
import { Item } from 'types/MarketplaceAdsResponse';
import {
  getCoverPhotoPath,
  getEquipmentValues,
  getSavingsValue,
} from 'components/views/ProductList/actions';
// import { Blurbs } from './Blurbs'; Removed until inventory filters are implemented
import '@splidejs/react-splide/css/core';
import { Icon } from 'components/tokens/Icon';
import { chevronLeft, chevronRight } from 'icons/default';
import { Colors } from 'enums/Colors';

import styles from './Proof.module.scss';

interface ProofBlurb {
  title: string;
  imageSrc: string;
  href: string;
}

interface ProofUrl {
  href: string;
  label: string;
}

export interface ProofProps {
  title?: string;
  subTitle?: string;
  intro?: string;
  theme?: 'dark' | 'light';
  cars: Item[];
  blurbs: ProofBlurb[];
  urls: ProofUrl[];
  buttons?: boolean;
  variant?: string;
}

export const Proof: React.FC<ProofProps> = ({
  title,
  subTitle,
  intro,
  theme = 'light',
  cars,
  variant = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  blurbs,
  urls,
  buttons = true,
}) => {
  const [isMobile, setIsMobile] = React.useState(Boolean);
  const max768px = useMediaQuery('(max-width: 768px)');

  React.useEffect(() => {
    setIsMobile(max768px);
  }, [max768px]);

  return (
    <div className={`${styles.wrapper} ${styles[variant]}`}>
      {title && <h2 className={styles.big}>{title}</h2>}
      {intro && <p className={styles.intro}>{intro}</p>}
      {subTitle && !title && <h2 className={styles.small}>{subTitle}</h2>}
      {subTitle && title && <h3>{subTitle}</h3>}
      <div className={styles.content}>
        <Splide
          hasTrack={false}
          options={{
            arrows: true,
            autoWidth: true,
            pagination: false,
            perPage: 3,
            gap: '1rem',
            padding:
              variant === 'box' ? { right: '6rem', left: '6rem' } : { right: '0', left: '0' },
            classes: {
              arrows: 'proof__arrows',
              arrow: 'proof__arrow',
              prev: 'proof__arrow--prev',
              next: 'proof__arrow--next',
            },
            breakpoints: {
              480: {
                perPage: 1,
                padding: { right: '1.5rem', left: '1.5rem' },
              },
              1144: {
                perPage: 2,
                padding: { right: '1.5rem', left: '1.5rem' },
              },
            },
          }}
          aria-label={subTitle}
        >
          <div className="splide__arrows">
            <button type="button" className="splide__arrow splide__arrow--prev">
              <Icon icon={chevronLeft} color={Colors.White} size={20} />
            </button>
            <button type="button" className="splide__arrow splide__arrow--next">
              <Icon icon={chevronRight} color={Colors.White} size={20} />
            </button>
          </div>
          <SplideTrack>
            {cars.map((car) => {
              const savings = getSavingsValue(car);
              const equipment = getEquipmentValues(car);
              const cover = getCoverPhotoPath(car);

              return (
                <SplideSlide key={car.entity_id}>
                  <ProductCard
                    environmentally_friendly={car.vehicle.environmentally_friendly}
                    equipment={equipment}
                    fuel={car.vehicle.fuel ?? undefined}
                    gearbox={car.vehicle.gearbox ?? undefined}
                    id={car.entity_id}
                    imageSrc={cover}
                    km={Number(car.typeform.mileage.values[0].value)}
                    location={car.municipality?.name ?? ''}
                    first_time_in_traffic={car.vehicle.first_time_in_traffic}
                    monthly_cost={car.vehicle.monthly_cost.monthly_cost}
                    price={car.price ?? 0}
                    savings={savings}
                    subtitle={`${car.vehicle.trim_package ? `${car.vehicle.trim_package},` : ''} ${
                      car.vehicle.drive_wheels_type ? `${car.vehicle.drive_wheels_type},` : ''
                    } ${car.vehicle.horsepower ? `${car.vehicle.horsepower} hk` : ''}`}
                    title={`${car.vehicle.brand} ${
                      car.vehicle.model_gen_engine ?? car.vehicle.model
                    } ${car.vehicle.model_year}`}
                    vehicleType={car.vehicle.vehicle_type}
                    theme={ProductCardTheme.Dark}
                    is_company_leasable={car.vehicle.is_company_leasable}
                    customCarTitle={car.custom_car_title}
                  />
                </SplideSlide>
              );
            })}
          </SplideTrack>
        </Splide>
        {buttons && (
          <div className={styles.buttons}>
            <Button
              type={ButtonType.Button}
              size={isMobile ? ButtonSize.Small : ButtonSize.Large}
              variant={theme === 'light' ? ButtonVariant.PrimaryMintSweet : ButtonVariant.Primary}
              href={urls[0].href}
              isJustifyCenter
            >
              {urls[0].label}
            </Button>
            <Button
              type={ButtonType.Button}
              size={isMobile ? ButtonSize.Small : ButtonSize.Large}
              variant={theme === 'light' ? ButtonVariant.GhostInverted : ButtonVariant.Ghost}
              href={urls[1].href}
              isJustifyCenter
            >
              {urls[1].label}
            </Button>
          </div>
        )}

        {/* <Blurbs blurbs={blurbs} /> Removed until inventory filters are implemented */}
      </div>
    </div>
  );
};
